/*
* @Author: docoder
* @Email:  docoder@163.com
*/
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styles from './footer.module.scss'

const Footer = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    author
                }
            }
        }
    `)

    return (
        <footer className={styles.footer}>
            <p>Created by {data.site.siteMetadata.author} © 2019</p>
        </footer>
    )
}

export default Footer